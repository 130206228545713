/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Attachment,
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
    EducationResponse,
    EducationResponseFromJSON,
    EducationResponseFromJSONTyped,
    EducationResponseToJSON,
    EmploymentHistoryResponse,
    EmploymentHistoryResponseFromJSON,
    EmploymentHistoryResponseFromJSONTyped,
    EmploymentHistoryResponseToJSON,
    Option,
    OptionFromJSON,
    OptionFromJSONTyped,
    OptionToJSON,
    ResponseToApplicant,
    ResponseToApplicantFromJSON,
    ResponseToApplicantFromJSONTyped,
    ResponseToApplicantToJSON,
    ScholarshipResponse,
    ScholarshipResponseFromJSON,
    ScholarshipResponseFromJSONTyped,
    ScholarshipResponseToJSON,
    StudyPlan,
    StudyPlanFromJSON,
    StudyPlanFromJSONTyped,
    StudyPlanToJSON,
    SupplementalMaterialResponse,
    SupplementalMaterialResponseFromJSON,
    SupplementalMaterialResponseFromJSONTyped,
    SupplementalMaterialResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApplicationResponse
 */
export interface ApplicationResponse {
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    applicantId: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    binderId: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    state: ApplicationResponseStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    reentry: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    resubmit: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    duplicated?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponse
     */
    sentAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponse
     */
    firstSentAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponse
     */
    repliedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    aaoNote?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    idCardNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    familyName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    givenName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    familyNameKana?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    middleNameKana?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    givenNameKana?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    familyNameInChinese?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    middleNameInChinese?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    givenNameInChinese?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    gender?: ApplicationResponseGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    genderNeedSupport?: ApplicationResponseGenderNeedSupportEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    genderNeedSupportDetail?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponse
     */
    birthdate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    marriageStatus?: ApplicationResponseMarriageStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    otherNationality?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    primaryPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    secondaryPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    emergencyFamilyName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    emergencyMiddleName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    emergencyGivenName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    emergencyRelationship?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    emergencyPostalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    emergencyAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    emergencyEmailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    emergencyPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    needScholarship?: ApplicationResponseNeedScholarshipEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    scholarshipType?: ApplicationResponseScholarshipTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    needOtherScholarship?: ApplicationResponseNeedOtherScholarshipEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    otherScholarshipName?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponse
     */
    anticipatedMonthOfOtherScholarshipResult?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    canStudyAbroadWithoutScholarship?: ApplicationResponseCanStudyAbroadWithoutScholarshipEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    reasonOfImpossibilityWithoutScholarship?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    needCredits?: ApplicationResponseNeedCreditsEnum;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    numberOfNecessaryCredits?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    springCourseList?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    fallCourseList?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    isApprovePrivacy?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    creditSupervisor?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    titleOfResearch?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    researchSupervisor?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    courseNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    isNativeSpeaker?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    currentAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    currentStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    supervisor?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    researchTopic?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    otherProgram?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponse
     */
    anticipatedEnrollmentDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    anticipatedEnrollmentTerm?: ApplicationResponseAnticipatedEnrollmentTermEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    anticipatedEnrollmentProgram?: ApplicationResponseAnticipatedEnrollmentProgramEnum;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    anticipatedDepartmentId?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    anticipatedMajorId?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    language?: ApplicationResponseLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    notes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    isApproveShowInfoToUniversity?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    healthHaveDisease?: ApplicationResponseHealthHaveDiseaseEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    healthHaveDiseaseDetail?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    healthNeedMedicine?: ApplicationResponseHealthNeedMedicineEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    healthNeedMedicineDetail?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    healthGetSupport?: ApplicationResponseHealthGetSupportEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    healthGetSupportDetail?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    healthNeedSupport?: ApplicationResponseHealthNeedSupportEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    healthNeedSupportDetail?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    version: number;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {ResponseToApplicant}
     * @memberof ApplicationResponse
     */
    acceptResponse?: ResponseToApplicant;
    /**
     * 
     * @type {ResponseToApplicant}
     * @memberof ApplicationResponse
     */
    returnResponse?: ResponseToApplicant;
    /**
     * 
     * @type {Array<EducationResponse>}
     * @memberof ApplicationResponse
     */
    educations: Array<EducationResponse>;
    /**
     * 
     * @type {Array<ScholarshipResponse>}
     * @memberof ApplicationResponse
     */
    scholarships: Array<ScholarshipResponse>;
    /**
     * 
     * @type {Array<SupplementalMaterialResponse>}
     * @memberof ApplicationResponse
     */
    supplementalMaterials: Array<SupplementalMaterialResponse>;
    /**
     * 
     * @type {Array<StudyPlan>}
     * @memberof ApplicationResponse
     */
    studyPlans: Array<StudyPlan>;
    /**
     * 
     * @type {Array<EmploymentHistoryResponse>}
     * @memberof ApplicationResponse
     */
    employmentHistories: Array<EmploymentHistoryResponse>;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof ApplicationResponse
     */
    recommendationLetters: Array<Attachment>;
    /**
     * 
     * @type {Attachment}
     * @memberof ApplicationResponse
     */
    photo?: Attachment;
    /**
     * 
     * @type {Attachment}
     * @memberof ApplicationResponse
     */
    statementOfPurpose?: Attachment;
    /**
     * 
     * @type {Attachment}
     * @memberof ApplicationResponse
     */
    studyPlanAttachment?: Attachment;
    /**
     * 
     * @type {Attachment}
     * @memberof ApplicationResponse
     */
    studyPlanAttachment2?: Attachment;
    /**
     * 
     * @type {Attachment}
     * @memberof ApplicationResponse
     */
    essayForJtwApplicant?: Attachment;
    /**
     * 
     * @type {Attachment}
     * @memberof ApplicationResponse
     */
    ispForJtwApplicants?: Attachment;
    /**
     * 
     * @type {Attachment}
     * @memberof ApplicationResponse
     */
    alrForJtwApplicants?: Attachment;
    /**
     * 
     * @type {Attachment}
     * @memberof ApplicationResponse
     */
    passportAttachment?: Attachment;
    /**
     * 
     * @type {Attachment}
     * @memberof ApplicationResponse
     */
    researchPlanAttachment?: Attachment;
    /**
     * 
     * @type {Option}
     * @memberof ApplicationResponse
     */
    course?: Option;
    /**
     * 
     * @type {Option}
     * @memberof ApplicationResponse
     */
    program?: Option;
    /**
     * 
     * @type {Option}
     * @memberof ApplicationResponse
     */
    nationality?: Option;
    /**
     * 
     * @type {Option}
     * @memberof ApplicationResponse
     */
    secondNationality?: Option;
    /**
     * 
     * @type {Option}
     * @memberof ApplicationResponse
     */
    emergencyNationality?: Option;
    /**
     * 
     * @type {Option}
     * @memberof ApplicationResponse
     */
    financialSupport?: Option;
    /**
     * 
     * @type {Option}
     * @memberof ApplicationResponse
     */
    japaneseLevel?: Option;
    /**
     * 
     * @type {Option}
     * @memberof ApplicationResponse
     */
    englishLevel?: Option;
}

/**
* @export
* @enum {string}
*/
export enum ApplicationResponseStateEnum {
    Draft = 'Draft',
    Sent = 'Sent',
    Reviewing = 'Reviewing',
    ApproveRequested = 'ApproveRequested',
    ApproveCanceled = 'ApproveCanceled',
    ApproveAccepted = 'ApproveAccepted',
    ApproveRejected = 'ApproveRejected',
    RelayRequested = 'RelayRequested',
    RelayCanceled = 'RelayCanceled',
    RelayTeacherRequested = 'RelayTeacherRequested',
    RelayTeacherCanceled = 'RelayTeacherCanceled',
    RelayTeacherAccepted = 'RelayTeacherAccepted',
    RelayTeacherRejected = 'RelayTeacherRejected',
    RelayAccepted = 'RelayAccepted',
    RelayRejected = 'RelayRejected',
    Direct = 'Direct',
    CanceledReentryAllowed = 'CanceledReentryAllowed',
    Canceled = 'Canceled',
    Accepted = 'Accepted',
    RejectedReentryAllowed = 'RejectedReentryAllowed',
    Rejected = 'Rejected',
    Old = 'Old'
}/**
* @export
* @enum {string}
*/
export enum ApplicationResponseGenderEnum {
    Male = 'Male',
    Female = 'Female',
    Others = 'Others'
}/**
* @export
* @enum {string}
*/
export enum ApplicationResponseGenderNeedSupportEnum {
    Yes = 'Yes',
    No = 'No'
}/**
* @export
* @enum {string}
*/
export enum ApplicationResponseMarriageStatusEnum {
    Single = 'Single',
    Married = 'Married'
}/**
* @export
* @enum {string}
*/
export enum ApplicationResponseNeedScholarshipEnum {
    Yes = 'Yes',
    No = 'No'
}/**
* @export
* @enum {string}
*/
export enum ApplicationResponseScholarshipTypeEnum {
    Jasso = 'JASSO',
    Mext = 'MEXT'
}/**
* @export
* @enum {string}
*/
export enum ApplicationResponseNeedOtherScholarshipEnum {
    Yes = 'Yes',
    No = 'No'
}/**
* @export
* @enum {string}
*/
export enum ApplicationResponseCanStudyAbroadWithoutScholarshipEnum {
    Yes = 'Yes',
    No = 'No'
}/**
* @export
* @enum {string}
*/
export enum ApplicationResponseNeedCreditsEnum {
    Yes = 'Yes',
    No = 'No'
}/**
* @export
* @enum {string}
*/
export enum ApplicationResponseAnticipatedEnrollmentTermEnum {
    SpringFall = 'SpringFall',
    FallSpring = 'FallSpring',
    Spring = 'Spring',
    Fall = 'Fall',
    FallSpringForJtw = 'FallSpringForJTW',
    FallForJtw = 'FallForJTW',
    SpringForJtw = 'SpringForJTW',
    FallSpringForJlcc = 'FallSpringForJLCC',
    ArrangeForQpels = 'ArrangeForQPELS',
    FallForQpels = 'FallForQPELS',
    SpringForQpels = 'SpringForQPELS',
    FallSpringForQpels = 'FallSpringForQPELS'
}/**
* @export
* @enum {string}
*/
export enum ApplicationResponseAnticipatedEnrollmentProgramEnum {
    GakubuGakufu = 'GakubuGakufu',
    Jtw = 'JTW',
    Jlcc = 'JLCC',
    Qpels = 'QPELS'
}/**
* @export
* @enum {string}
*/
export enum ApplicationResponseLanguageEnum {
    Ja = 'Ja',
    En = 'En'
}/**
* @export
* @enum {string}
*/
export enum ApplicationResponseHealthHaveDiseaseEnum {
    Yes = 'Yes',
    No = 'No'
}/**
* @export
* @enum {string}
*/
export enum ApplicationResponseHealthNeedMedicineEnum {
    Yes = 'Yes',
    No = 'No'
}/**
* @export
* @enum {string}
*/
export enum ApplicationResponseHealthGetSupportEnum {
    Yes = 'Yes',
    No = 'No'
}/**
* @export
* @enum {string}
*/
export enum ApplicationResponseHealthNeedSupportEnum {
    Yes = 'Yes',
    No = 'No'
}

export function ApplicationResponseFromJSON(json: any): ApplicationResponse {
    return ApplicationResponseFromJSONTyped(json, false);
}

export function ApplicationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'applicantId': json['applicantId'],
        'binderId': json['binderId'],
        'state': json['state'],
        'reentry': json['reentry'],
        'resubmit': json['resubmit'],
        'duplicated': !exists(json, 'duplicated') ? undefined : json['duplicated'],
        'sentAt': !exists(json, 'sentAt') ? undefined : (new Date(json['sentAt'])),
        'firstSentAt': !exists(json, 'firstSentAt') ? undefined : (new Date(json['firstSentAt'])),
        'repliedAt': !exists(json, 'repliedAt') ? undefined : (new Date(json['repliedAt'])),
        'aaoNote': !exists(json, 'aaoNote') ? undefined : json['aaoNote'],
        'idCardNumber': !exists(json, 'idCardNumber') ? undefined : json['idCardNumber'],
        'familyName': !exists(json, 'familyName') ? undefined : json['familyName'],
        'middleName': !exists(json, 'middleName') ? undefined : json['middleName'],
        'givenName': !exists(json, 'givenName') ? undefined : json['givenName'],
        'familyNameKana': !exists(json, 'familyNameKana') ? undefined : json['familyNameKana'],
        'middleNameKana': !exists(json, 'middleNameKana') ? undefined : json['middleNameKana'],
        'givenNameKana': !exists(json, 'givenNameKana') ? undefined : json['givenNameKana'],
        'familyNameInChinese': !exists(json, 'familyNameInChinese') ? undefined : json['familyNameInChinese'],
        'middleNameInChinese': !exists(json, 'middleNameInChinese') ? undefined : json['middleNameInChinese'],
        'givenNameInChinese': !exists(json, 'givenNameInChinese') ? undefined : json['givenNameInChinese'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'genderNeedSupport': !exists(json, 'genderNeedSupport') ? undefined : json['genderNeedSupport'],
        'genderNeedSupportDetail': !exists(json, 'genderNeedSupportDetail') ? undefined : json['genderNeedSupportDetail'],
        'birthdate': !exists(json, 'birthdate') ? undefined : (new Date(json['birthdate'])),
        'marriageStatus': !exists(json, 'marriageStatus') ? undefined : json['marriageStatus'],
        'otherNationality': !exists(json, 'otherNationality') ? undefined : json['otherNationality'],
        'primaryPhoneNumber': !exists(json, 'primaryPhoneNumber') ? undefined : json['primaryPhoneNumber'],
        'secondaryPhoneNumber': !exists(json, 'secondaryPhoneNumber') ? undefined : json['secondaryPhoneNumber'],
        'emergencyFamilyName': !exists(json, 'emergencyFamilyName') ? undefined : json['emergencyFamilyName'],
        'emergencyMiddleName': !exists(json, 'emergencyMiddleName') ? undefined : json['emergencyMiddleName'],
        'emergencyGivenName': !exists(json, 'emergencyGivenName') ? undefined : json['emergencyGivenName'],
        'emergencyRelationship': !exists(json, 'emergencyRelationship') ? undefined : json['emergencyRelationship'],
        'emergencyPostalCode': !exists(json, 'emergencyPostalCode') ? undefined : json['emergencyPostalCode'],
        'emergencyAddress': !exists(json, 'emergencyAddress') ? undefined : json['emergencyAddress'],
        'emergencyEmailAddress': !exists(json, 'emergencyEmailAddress') ? undefined : json['emergencyEmailAddress'],
        'emergencyPhoneNumber': !exists(json, 'emergencyPhoneNumber') ? undefined : json['emergencyPhoneNumber'],
        'needScholarship': !exists(json, 'needScholarship') ? undefined : json['needScholarship'],
        'scholarshipType': !exists(json, 'scholarshipType') ? undefined : json['scholarshipType'],
        'needOtherScholarship': !exists(json, 'needOtherScholarship') ? undefined : json['needOtherScholarship'],
        'otherScholarshipName': !exists(json, 'otherScholarshipName') ? undefined : json['otherScholarshipName'],
        'anticipatedMonthOfOtherScholarshipResult': !exists(json, 'anticipatedMonthOfOtherScholarshipResult') ? undefined : (new Date(json['anticipatedMonthOfOtherScholarshipResult'])),
        'canStudyAbroadWithoutScholarship': !exists(json, 'canStudyAbroadWithoutScholarship') ? undefined : json['canStudyAbroadWithoutScholarship'],
        'reasonOfImpossibilityWithoutScholarship': !exists(json, 'reasonOfImpossibilityWithoutScholarship') ? undefined : json['reasonOfImpossibilityWithoutScholarship'],
        'needCredits': !exists(json, 'needCredits') ? undefined : json['needCredits'],
        'numberOfNecessaryCredits': !exists(json, 'numberOfNecessaryCredits') ? undefined : json['numberOfNecessaryCredits'],
        'springCourseList': !exists(json, 'springCourseList') ? undefined : json['springCourseList'],
        'fallCourseList': !exists(json, 'fallCourseList') ? undefined : json['fallCourseList'],
        'isApprovePrivacy': !exists(json, 'isApprovePrivacy') ? undefined : json['isApprovePrivacy'],
        'creditSupervisor': !exists(json, 'creditSupervisor') ? undefined : json['creditSupervisor'],
        'titleOfResearch': !exists(json, 'titleOfResearch') ? undefined : json['titleOfResearch'],
        'researchSupervisor': !exists(json, 'researchSupervisor') ? undefined : json['researchSupervisor'],
        'courseNumber': !exists(json, 'courseNumber') ? undefined : json['courseNumber'],
        'isNativeSpeaker': !exists(json, 'isNativeSpeaker') ? undefined : json['isNativeSpeaker'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'currentAddress': !exists(json, 'currentAddress') ? undefined : json['currentAddress'],
        'currentStatus': !exists(json, 'currentStatus') ? undefined : json['currentStatus'],
        'supervisor': !exists(json, 'supervisor') ? undefined : json['supervisor'],
        'researchTopic': !exists(json, 'researchTopic') ? undefined : json['researchTopic'],
        'otherProgram': !exists(json, 'otherProgram') ? undefined : json['otherProgram'],
        'anticipatedEnrollmentDate': !exists(json, 'anticipatedEnrollmentDate') ? undefined : (new Date(json['anticipatedEnrollmentDate'])),
        'anticipatedEnrollmentTerm': !exists(json, 'anticipatedEnrollmentTerm') ? undefined : json['anticipatedEnrollmentTerm'],
        'anticipatedEnrollmentProgram': !exists(json, 'anticipatedEnrollmentProgram') ? undefined : json['anticipatedEnrollmentProgram'],
        'anticipatedDepartmentId': !exists(json, 'anticipatedDepartmentId') ? undefined : json['anticipatedDepartmentId'],
        'anticipatedMajorId': !exists(json, 'anticipatedMajorId') ? undefined : json['anticipatedMajorId'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'isApproveShowInfoToUniversity': !exists(json, 'isApproveShowInfoToUniversity') ? undefined : json['isApproveShowInfoToUniversity'],
        'healthHaveDisease': !exists(json, 'healthHaveDisease') ? undefined : json['healthHaveDisease'],
        'healthHaveDiseaseDetail': !exists(json, 'healthHaveDiseaseDetail') ? undefined : json['healthHaveDiseaseDetail'],
        'healthNeedMedicine': !exists(json, 'healthNeedMedicine') ? undefined : json['healthNeedMedicine'],
        'healthNeedMedicineDetail': !exists(json, 'healthNeedMedicineDetail') ? undefined : json['healthNeedMedicineDetail'],
        'healthGetSupport': !exists(json, 'healthGetSupport') ? undefined : json['healthGetSupport'],
        'healthGetSupportDetail': !exists(json, 'healthGetSupportDetail') ? undefined : json['healthGetSupportDetail'],
        'healthNeedSupport': !exists(json, 'healthNeedSupport') ? undefined : json['healthNeedSupport'],
        'healthNeedSupportDetail': !exists(json, 'healthNeedSupportDetail') ? undefined : json['healthNeedSupportDetail'],
        'version': json['version'],
        'createdAt': (new Date(json['createdAt'])),
        'acceptResponse': !exists(json, 'acceptResponse') ? undefined : ResponseToApplicantFromJSON(json['acceptResponse']),
        'returnResponse': !exists(json, 'returnResponse') ? undefined : ResponseToApplicantFromJSON(json['returnResponse']),
        'educations': ((json['educations'] as Array<any>).map(EducationResponseFromJSON)),
        'scholarships': ((json['scholarships'] as Array<any>).map(ScholarshipResponseFromJSON)),
        'supplementalMaterials': ((json['supplementalMaterials'] as Array<any>).map(SupplementalMaterialResponseFromJSON)),
        'studyPlans': ((json['studyPlans'] as Array<any>).map(StudyPlanFromJSON)),
        'employmentHistories': ((json['employmentHistories'] as Array<any>).map(EmploymentHistoryResponseFromJSON)),
        'recommendationLetters': ((json['recommendationLetters'] as Array<any>).map(AttachmentFromJSON)),
        'photo': !exists(json, 'photo') ? undefined : AttachmentFromJSON(json['photo']),
        'statementOfPurpose': !exists(json, 'statementOfPurpose') ? undefined : AttachmentFromJSON(json['statementOfPurpose']),
        'studyPlanAttachment': !exists(json, 'studyPlanAttachment') ? undefined : AttachmentFromJSON(json['studyPlanAttachment']),
        'studyPlanAttachment2': !exists(json, 'studyPlanAttachment2') ? undefined : AttachmentFromJSON(json['studyPlanAttachment2']),
        'essayForJtwApplicant': !exists(json, 'essayForJtwApplicant') ? undefined : AttachmentFromJSON(json['essayForJtwApplicant']),
        'ispForJtwApplicants': !exists(json, 'ispForJtwApplicants') ? undefined : AttachmentFromJSON(json['ispForJtwApplicants']),
        'alrForJtwApplicants': !exists(json, 'alrForJtwApplicants') ? undefined : AttachmentFromJSON(json['alrForJtwApplicants']),
        'passportAttachment': !exists(json, 'passportAttachment') ? undefined : AttachmentFromJSON(json['passportAttachment']),
        'researchPlanAttachment': !exists(json, 'researchPlanAttachment') ? undefined : AttachmentFromJSON(json['researchPlanAttachment']),
        'course': !exists(json, 'course') ? undefined : OptionFromJSON(json['course']),
        'program': !exists(json, 'program') ? undefined : OptionFromJSON(json['program']),
        'nationality': !exists(json, 'nationality') ? undefined : OptionFromJSON(json['nationality']),
        'secondNationality': !exists(json, 'secondNationality') ? undefined : OptionFromJSON(json['secondNationality']),
        'emergencyNationality': !exists(json, 'emergencyNationality') ? undefined : OptionFromJSON(json['emergencyNationality']),
        'financialSupport': !exists(json, 'financialSupport') ? undefined : OptionFromJSON(json['financialSupport']),
        'japaneseLevel': !exists(json, 'japaneseLevel') ? undefined : OptionFromJSON(json['japaneseLevel']),
        'englishLevel': !exists(json, 'englishLevel') ? undefined : OptionFromJSON(json['englishLevel']),
    };
}

export function ApplicationResponseToJSON(value?: ApplicationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'applicantId': value.applicantId,
        'binderId': value.binderId,
        'state': value.state,
        'reentry': value.reentry,
        'resubmit': value.resubmit,
        'duplicated': value.duplicated,
        'sentAt': value.sentAt === undefined ? undefined : (value.sentAt.toISOString()),
        'firstSentAt': value.firstSentAt === undefined ? undefined : (value.firstSentAt.toISOString()),
        'repliedAt': value.repliedAt === undefined ? undefined : (value.repliedAt.toISOString()),
        'aaoNote': value.aaoNote,
        'idCardNumber': value.idCardNumber,
        'familyName': value.familyName,
        'middleName': value.middleName,
        'givenName': value.givenName,
        'familyNameKana': value.familyNameKana,
        'middleNameKana': value.middleNameKana,
        'givenNameKana': value.givenNameKana,
        'familyNameInChinese': value.familyNameInChinese,
        'middleNameInChinese': value.middleNameInChinese,
        'givenNameInChinese': value.givenNameInChinese,
        'gender': value.gender,
        'genderNeedSupport': value.genderNeedSupport,
        'genderNeedSupportDetail': value.genderNeedSupportDetail,
        'birthdate': value.birthdate === undefined ? undefined : (value.birthdate.toISOString()),
        'marriageStatus': value.marriageStatus,
        'otherNationality': value.otherNationality,
        'primaryPhoneNumber': value.primaryPhoneNumber,
        'secondaryPhoneNumber': value.secondaryPhoneNumber,
        'emergencyFamilyName': value.emergencyFamilyName,
        'emergencyMiddleName': value.emergencyMiddleName,
        'emergencyGivenName': value.emergencyGivenName,
        'emergencyRelationship': value.emergencyRelationship,
        'emergencyPostalCode': value.emergencyPostalCode,
        'emergencyAddress': value.emergencyAddress,
        'emergencyEmailAddress': value.emergencyEmailAddress,
        'emergencyPhoneNumber': value.emergencyPhoneNumber,
        'needScholarship': value.needScholarship,
        'scholarshipType': value.scholarshipType,
        'needOtherScholarship': value.needOtherScholarship,
        'otherScholarshipName': value.otherScholarshipName,
        'anticipatedMonthOfOtherScholarshipResult': value.anticipatedMonthOfOtherScholarshipResult === undefined ? undefined : (value.anticipatedMonthOfOtherScholarshipResult.toISOString()),
        'canStudyAbroadWithoutScholarship': value.canStudyAbroadWithoutScholarship,
        'reasonOfImpossibilityWithoutScholarship': value.reasonOfImpossibilityWithoutScholarship,
        'needCredits': value.needCredits,
        'numberOfNecessaryCredits': value.numberOfNecessaryCredits,
        'springCourseList': value.springCourseList,
        'fallCourseList': value.fallCourseList,
        'isApprovePrivacy': value.isApprovePrivacy,
        'creditSupervisor': value.creditSupervisor,
        'titleOfResearch': value.titleOfResearch,
        'researchSupervisor': value.researchSupervisor,
        'courseNumber': value.courseNumber,
        'isNativeSpeaker': value.isNativeSpeaker,
        'postalCode': value.postalCode,
        'currentAddress': value.currentAddress,
        'currentStatus': value.currentStatus,
        'supervisor': value.supervisor,
        'researchTopic': value.researchTopic,
        'otherProgram': value.otherProgram,
        'anticipatedEnrollmentDate': value.anticipatedEnrollmentDate === undefined ? undefined : (value.anticipatedEnrollmentDate.toISOString()),
        'anticipatedEnrollmentTerm': value.anticipatedEnrollmentTerm,
        'anticipatedEnrollmentProgram': value.anticipatedEnrollmentProgram,
        'anticipatedDepartmentId': value.anticipatedDepartmentId,
        'anticipatedMajorId': value.anticipatedMajorId,
        'language': value.language,
        'notes': value.notes,
        'isApproveShowInfoToUniversity': value.isApproveShowInfoToUniversity,
        'healthHaveDisease': value.healthHaveDisease,
        'healthHaveDiseaseDetail': value.healthHaveDiseaseDetail,
        'healthNeedMedicine': value.healthNeedMedicine,
        'healthNeedMedicineDetail': value.healthNeedMedicineDetail,
        'healthGetSupport': value.healthGetSupport,
        'healthGetSupportDetail': value.healthGetSupportDetail,
        'healthNeedSupport': value.healthNeedSupport,
        'healthNeedSupportDetail': value.healthNeedSupportDetail,
        'version': value.version,
        'createdAt': (value.createdAt.toISOString()),
        'acceptResponse': ResponseToApplicantToJSON(value.acceptResponse),
        'returnResponse': ResponseToApplicantToJSON(value.returnResponse),
        'educations': ((value.educations as Array<any>).map(EducationResponseToJSON)),
        'scholarships': ((value.scholarships as Array<any>).map(ScholarshipResponseToJSON)),
        'supplementalMaterials': ((value.supplementalMaterials as Array<any>).map(SupplementalMaterialResponseToJSON)),
        'studyPlans': ((value.studyPlans as Array<any>).map(StudyPlanToJSON)),
        'employmentHistories': ((value.employmentHistories as Array<any>).map(EmploymentHistoryResponseToJSON)),
        'recommendationLetters': ((value.recommendationLetters as Array<any>).map(AttachmentToJSON)),
        'photo': AttachmentToJSON(value.photo),
        'statementOfPurpose': AttachmentToJSON(value.statementOfPurpose),
        'studyPlanAttachment': AttachmentToJSON(value.studyPlanAttachment),
        'studyPlanAttachment2': AttachmentToJSON(value.studyPlanAttachment2),
        'essayForJtwApplicant': AttachmentToJSON(value.essayForJtwApplicant),
        'ispForJtwApplicants': AttachmentToJSON(value.ispForJtwApplicants),
        'alrForJtwApplicants': AttachmentToJSON(value.alrForJtwApplicants),
        'passportAttachment': AttachmentToJSON(value.passportAttachment),
        'researchPlanAttachment': AttachmentToJSON(value.researchPlanAttachment),
        'course': OptionToJSON(value.course),
        'program': OptionToJSON(value.program),
        'nationality': OptionToJSON(value.nationality),
        'secondNationality': OptionToJSON(value.secondNationality),
        'emergencyNationality': OptionToJSON(value.emergencyNationality),
        'financialSupport': OptionToJSON(value.financialSupport),
        'japaneseLevel': OptionToJSON(value.japaneseLevel),
        'englishLevel': OptionToJSON(value.englishLevel),
    };
}


