/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Attachment,
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
    ExchangeProgramPartnerSchool,
    ExchangeProgramPartnerSchoolFromJSON,
    ExchangeProgramPartnerSchoolFromJSONTyped,
    ExchangeProgramPartnerSchoolToJSON,
    Option,
    OptionFromJSON,
    OptionFromJSONTyped,
    OptionToJSON,
    University,
    UniversityFromJSON,
    UniversityFromJSONTyped,
    UniversityToJSON,
} from './';

/**
 * 
 * @export
 * @interface EducationResponse
 */
export interface EducationResponse {
    /**
     * 
     * @type {string}
     * @memberof EducationResponse
     */
    systemOther?: string;
    /**
     * 
     * @type {string}
     * @memberof EducationResponse
     */
    schoolName?: string;
    /**
     * 
     * @type {number}
     * @memberof EducationResponse
     */
    categoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationResponse
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof EducationResponse
     */
    major?: string;
    /**
     * 
     * @type {Date}
     * @memberof EducationResponse
     */
    durationFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EducationResponse
     */
    durationTo?: Date;
    /**
     * 
     * @type {string}
     * @memberof EducationResponse
     */
    grade?: string;
    /**
     * 
     * @type {string}
     * @memberof EducationResponse
     */
    otherExchangeProgramPartnerSchoolName?: string;
    /**
     * 
     * @type {string}
     * @memberof EducationResponse
     */
    gpa?: string;
    /**
     * 
     * @type {number}
     * @memberof EducationResponse
     */
    displayOrder: number;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof EducationResponse
     */
    attachments?: Array<Attachment>;
    /**
     * 
     * @type {Option}
     * @memberof EducationResponse
     */
    type?: Option;
    /**
     * 
     * @type {Option}
     * @memberof EducationResponse
     */
    country?: Option;
    /**
     * 
     * @type {Option}
     * @memberof EducationResponse
     */
    system?: Option;
    /**
     * 
     * @type {University}
     * @memberof EducationResponse
     */
    university?: University;
    /**
     * 
     * @type {ExchangeProgramPartnerSchool}
     * @memberof EducationResponse
     */
    exchangeProgramPartnerSchool?: ExchangeProgramPartnerSchool;
}

export function EducationResponseFromJSON(json: any): EducationResponse {
    return EducationResponseFromJSONTyped(json, false);
}

export function EducationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EducationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'systemOther': !exists(json, 'systemOther') ? undefined : json['systemOther'],
        'schoolName': !exists(json, 'schoolName') ? undefined : json['schoolName'],
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'major': !exists(json, 'major') ? undefined : json['major'],
        'durationFrom': !exists(json, 'durationFrom') ? undefined : (new Date(json['durationFrom'])),
        'durationTo': !exists(json, 'durationTo') ? undefined : (new Date(json['durationTo'])),
        'grade': !exists(json, 'grade') ? undefined : json['grade'],
        'otherExchangeProgramPartnerSchoolName': !exists(json, 'otherExchangeProgramPartnerSchoolName') ? undefined : json['otherExchangeProgramPartnerSchoolName'],
        'gpa': !exists(json, 'gpa') ? undefined : json['gpa'],
        'displayOrder': json['displayOrder'],
        'attachments': !exists(json, 'attachments') ? undefined : ((json['attachments'] as Array<any>).map(AttachmentFromJSON)),
        'type': !exists(json, 'type') ? undefined : OptionFromJSON(json['type']),
        'country': !exists(json, 'country') ? undefined : OptionFromJSON(json['country']),
        'system': !exists(json, 'system') ? undefined : OptionFromJSON(json['system']),
        'university': !exists(json, 'university') ? undefined : UniversityFromJSON(json['university']),
        'exchangeProgramPartnerSchool': !exists(json, 'exchangeProgramPartnerSchool') ? undefined : ExchangeProgramPartnerSchoolFromJSON(json['exchangeProgramPartnerSchool']),
    };
}

export function EducationResponseToJSON(value?: EducationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'systemOther': value.systemOther,
        'schoolName': value.schoolName,
        'categoryId': value.categoryId,
        'location': value.location,
        'major': value.major,
        'durationFrom': value.durationFrom === undefined ? undefined : (value.durationFrom.toISOString()),
        'durationTo': value.durationTo === undefined ? undefined : (value.durationTo.toISOString()),
        'grade': value.grade,
        'otherExchangeProgramPartnerSchoolName': value.otherExchangeProgramPartnerSchoolName,
        'gpa': value.gpa,
        'displayOrder': value.displayOrder,
        'attachments': value.attachments === undefined ? undefined : ((value.attachments as Array<any>).map(AttachmentToJSON)),
        'type': OptionToJSON(value.type),
        'country': OptionToJSON(value.country),
        'system': OptionToJSON(value.system),
        'university': UniversityToJSON(value.university),
        'exchangeProgramPartnerSchool': ExchangeProgramPartnerSchoolToJSON(value.exchangeProgramPartnerSchool),
    };
}


